<template>
    <v-card>
        <v-app-bar dark
                   fixed
        >
            <v-btn icon
                   dark
                   data-dismiss="modal"
                   @click="detailClose"
            >
                <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>상세 공지사항</v-toolbar-title>
            <v-spacer></v-spacer>

        </v-app-bar>

        <v-sheet
                id="scrolling-techniques-6"
                class="overflow-y-auto"
        >
            <v-container style="margin-top: 60px;">
                <v-col cols="12">
                    <h5 class="heading">
                        {{datas.title}}
                    </h5>
                    <span class="reg_date grey--text">
                        {{ datas.create_date }}
                    </span>
                </v-col>

                <v-divider class="mt-3 mb-3"></v-divider>

                <v-col cols="12">
                    <p class="body-2">
                        {{ datas.contents }}
                    </p>
                </v-col>

            </v-container>
        </v-sheet>

    </v-card>
    
</template>

<script>
    import axios from '../../service/axios'

    export default {
        name: "NoticeModal",
        data() {
            return{
                id: null,
                datas: {
                    title: null,
                    contents: null,
                    create_date: null,
                },
            }
        },
        props: {
            item_id: {
                type: String,
                require: false,
            },
        },
        created () {
            this.change_data();
            this.select_notice_data();
        },
        watch: {
            item_id(){
                this.change_data();
                this.select_notice_data();
            },
        },
        methods: {
            init_datas(){
                this.id = null;
                this.datas = {
                    title: null,
                    contents: null,
                    create_date: null,
                };
            },
            change_data(){
                if(this.$props.item_id != null){
                    this.id = this.$props.item_id;
                }
            },
            select_notice_data(){
                if(this.id != null){
                    const params = {
                        id: this.id,
                        accessKey: '',
                    }
                    axios.post('/api/notice/detail', params).then(res =>{
                        this.datas = res.data.data;
                    }).catch(error=>{
                        console.log('페스트 오류', error)
                    });
                }
            },
            detailClose(){
                this.init_datas();
                this.$emit('hide');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .v-toolbar__title{
        font-size: 1rem !important;
        color: #ffffff;
    }
</style>